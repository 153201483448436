<template>
  <b-card title="Accreditation">
    <b-tabs>
     <b-tab active @click="$router.push('/add-accreditation')">
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Add Accreditation</span>
        </template>
<AddAccreditation></AddAccreditation>
      </b-tab>
      <b-tab @click="$router.push('/all-accreditations')">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>  Accreditation</span>
        </template>
        <router-view></router-view>
    <!-- <AllAccreditation></AllAccreditation> -->
      </b-tab>
     
       
     
    </b-tabs>

  </b-card>
</template>

<script>

import { BTabs, BTab, BCardText,BCard } from 'bootstrap-vue'


import AllAccreditation from './AllAccreditation.vue';
import AddAccreditation from './AddAccreditation.vue';


export default {
  components: {
    BCard,
    BCardText,
    BTabs,
    BTab,
   
    AllAccreditation,
    AddAccreditation
},
  data() {
    return {
   
    }
  },
}
</script>
